export const responsive = {
  mobile: {
    maxWidth: 768,
  },
  tablet: {
    minWidth: 769,
    maxWidth: 1023,
  },
  computer: {
    minWidth: 1024,
  },
};
